import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, SearchBox, Hits } from 'react-instantsearch-dom';
import { connectStateResults } from "react-instantsearch-dom";

const searchClient = algoliasearch(process.env.ALGOLIA_APPID, process.env.ALGOLIA_APIKEY);;

const Hit = ({hit}) => {
    var { type } = hit;
    if(type === "post") {
        return (
            <div className="hit">
                <span style={{color: "#718096", fontSize: "14px", fontWeight: "600", marginBottom: "4px"}}>Post</span>
                <a href={hit.permalink || "#"}>
                    <h1>{hit.title}</h1>
                    {hit.plainText && 
                        <p>{hit.plainText.substring(0, 150).replace(/(\r\n|\n|\r)/gm, "")}</p>
                    }
                </a>
            </div>
        );
    }
    else if(type === "blog") {
        return (
            <div className="hit">
                <span style={{color: "#718096", fontSize: "14px", fontWeight: "600"}}>Blog</span>
                <a href={"//" + hit.subdomain + ".imprint.to" || "#"}>
                    <h1>{hit.title}</h1>
                    <p>{hit.description}</p>
                </a>
                <p className="domain">{hit.subdomain + ".imprint.to"}</p>
            </div>
        );
    }
    else if(type === "user") {
        return (
            <div className="hit">
                <span style={{color: "#718096", fontSize: "14px", fontWeight: "600"}}>User</span>
                <a href={hit.permalink || "#"}>
                {/* {
                    hit.fullName!=""?
                    <h1>{hit.fullName}</h1>
                    :
                    null
                } */}
                    <p>{hit.userName}</p>
                </a>
            </div>
        );
    }
    else {
        return null;
    }
}


const Content = connectStateResults(
    ({ searchState, searchResults }) => {
        if((searchState && !searchState.query) || (searchResults && searchResults.nbHits === 0)) {
            return null;
        }
        else {
            return <Hits hitComponent={Hit} />
        }
    }
);

function SearchBar() {
    return (
        <div className="searchBar" style={{WebkitFontSmoothing: "subpixel-antialiased"}}>
            <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/instantsearch.css@7.3.1/themes/reset-min.css" integrity="sha256-t2ATOGCtAIZNnzER679jwcFcKYfLlw01gli6F6oszk8=" crossOrigin="anonymous"/>

            <InstantSearch searchClient={searchClient} indexName={process.env.ALGOLIA_INDEXNAME}>
                <div className="searchBox">
                    <SearchBox placeholder="Search"/>
                </div>
                <div className="searchContent">
                    <Content />
                </div>
            </InstantSearch>
        </div>
    );
}

export default SearchBar;