import React, { useContext, useState } from 'react'
import Context from './Context'
import axios from 'axios';
import Router from 'next/router';
import SearchBar from './SearchBar'
import NotificationWidget from './NotificationWidget'
import SignUpModal from '../components/SignUpModal';
import logo from '../assets/logo.png'
import { firebase, auth } from '../firebase';
const api = process.env.API;

function SuperNavBar() {
    const context = useContext(Context);

    if(context.hideSuperNavBar === true) {
        return null;
    }

    const currentUser = auth.currentUser;

    var rightLinks;

    if(currentUser) {
        rightLinks = <LoggedIn />
    }
    else {
        rightLinks = <NotLoggedIn />
    }
    return (
        <React.Fragment>
            <div className="super-navbar">
                <div className="left">
                    <a href="/">                
                        <span className="logoText">realms</span>
                    </a>
                    {/* <SearchBar /> */}
                </div>
                {rightLinks}
            </div>
        </React.Fragment>
    );
}

function NotLoggedIn(props) {
    const [showSignUpModal, setShowSignUpModal] = useState(false);

    function toggle() {
        setShowSignUpModal(!showSignUpModal);
    }

    return (
        <div className="rightLinks">
            {showSignUpModal ?
                <SignUpModal toggle={toggle} />
                :
                null
            }
            <div className="nav-element" id="profile">
                <button onClick={toggle} className="sign-up-button">Join Realms</button>
            </div>
        </div>
    );
}

function LoggedIn(props) {
    const context = useContext(Context);
    const user = context.currentUser;

    var [stateUser] = useState(user);

    if(user === null) {
        //when get current user request not finished yet
        return (
            null
        );
    }
    else {
        var profilePicture = user.profilePicture;
        var fileName = profilePicture.split("/").pop();
        var resizedImage = `https://d1fj3zj4vkmoyq.cloudfront.net/fit-in/120x120/` + fileName;
        return (
            <div className="rightLinks">
                <div className="nav-element">
                    <div className="dropdown">
                        <input type="image" alt="profile" className="dropbtn" src={resizedImage}></input>
                        <div className="dropdown-content">
                            <a href={`/user/${user.username}`} className="user-dropdown-block">
                                <p>@{user.username}</p>
                            </a>
                            <a href="#" onClick={() => {
                                context.actions.signOut()
                            }}>Sign Out</a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SuperNavBar;