import React, { Component } from 'react'
import Context from './Context';
import axios from 'axios';
import { Bell } from 'react-feather';
const api = process.env.API;

export default class NotificationWidget extends Component {

    static contextType = Context;

    constructor(props) {
        super(props);
        this.state = {
            notificationCount: 0
        }
    }  

    componentDidMount() {
        var timesRun = 0;
        var checkExist =
            setInterval(() => {
                timesRun++;
                if (this.context.currentUser) {
                    this.getNotificationCount(this.context.currentUser._id);
                    clearInterval(checkExist);
                }
                //If can't find user, will quit after 1 second
                if(timesRun > 5) {
                    clearInterval(checkExist);
                }
            }, 200); // check every 200ms
    }

    getNotificationCount = (uID) => {
        axios.get(api + `/feed/userNotifications/${uID}/counts`)
        .then((res) => {
            this.setState({
                notificationCount: res.data
            });
        })
    }

    render() {
        if(this.context.currentUser) {
            return (
                <div>
                    <a href="/notifications" className="notification-bell">
                        <Bell color="#444444"/>
                        {this.state.notificationCount > 0 ?
                            <span className="badge">{this.state.notificationCount}</span>
                            : 
                            null
                        } 
                    </a>
                </div>
            );
        }
        else {
            return null;
        }
    }
}


